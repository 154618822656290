import { useLocation, useParams } from "react-router";
import { IStatementCurrencySection, IStatementLine, StatementLineType } from "shared";
import { useEffect, useMemo, useState, useReducer } from "react";
import { When } from "react-if";
import { toast } from "react-toastify";

import { useSession } from "../../hooks/useSession";
import { useFetchContactQuery } from "../../services/api/contact";
import { CurrentStatement } from "../../../common/Components/Contact/CurrentStatement";
import { useApiRequest } from "../../../main/hooks/useApiRequest";
import { env } from "../../../common/lib/env";
import { Button } from "../../../common/Atoms/Button";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Card } from "../../../common/Atoms/Card";
import { StripeCheckout } from "../Stripe/StripeCheckout";
import { Notification } from "../../../common/Atoms/Notification";
import { LoadingFallback } from "../LoadingFallback";

function loadingReducer(state: Record<string, boolean>, action: Record<string, boolean>) {
  return {
    ...state,
    ...action,
  };
}

export function Statements() {
  const { code } = useParams();
  const session = useSession();
  const { data: contactResult, isLoading, refetch } = useFetchContactQuery({ code, sessionId: session?.id }, { skip: !code || !session?.id });
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [invoiceIdsToPay, setInvoiceIdsToPay] = useState<string[]>([]);
  const [downloadingInvoices, setDownloadingInvoices] = useReducer(loadingReducer, {});
  const [checkoutStatus, setCheckoutStatus] = useState(null);
  const [downloadStatementLoading, setDownloadStatementLoading] = useState(false);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const request = useApiRequest();

  const stripeCurrentSessionId = useMemo(() => {
    return urlParams.get(`session_id`);
  }, [urlParams]);

  const shouldShowPayButton = (contactResult.stripeEnabled && contactResult.stripeConnectAccount && contactResult.statement.hasOutstandingBalance);

  useEffect(() => {
    if (stripeCurrentSessionId && !checkoutStatus && shouldShowPayButton) {
      fetch(`${env.basePortalApiUrl}stripe-checkout-status?sessionId=${stripeCurrentSessionId}&accountId=${contactResult.stripeConnectAccount}`)
        .then(res => res.json())
        .then(data => {
          setCheckoutStatus(data.status);

          // If successful, refresh the invoice data
        });
    }
  }, [stripeCurrentSessionId, checkoutStatus, shouldShowPayButton]);

  function onPay(currencySection: IStatementCurrencySection, type?: `overdue` | `outstanding`) {
    // Which invoices are we paying?
    if (type === `overdue`) {
      const invoiceIds = currencySection.lines
        .filter(l => l.type === StatementLineType.Invoice)
        .filter(l => l.debtAgeInDays > 1)
        .map(l => l.invoiceId);
      setInvoiceIdsToPay(invoiceIds);
    }

    if (type === `outstanding`) {
      const invoiceIds = currencySection.lines
        .filter(l => l.type === StatementLineType.Invoice)
        .map(l => l.invoiceId);
      setInvoiceIdsToPay(invoiceIds);
    }

    setPaymentModalOpen(true);
  }

  async function downloadStatement(currencyCode: string) {
    try {
      setDownloadStatementLoading(true);

      const result = await request({
        method: `GET`,
        responseType: `blob`,
        url: `${env.basePortalApiUrl}contact/code/${contactResult.hash}/statement.pdf`,
        params: {
          currency: currencyCode,
        },
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement(`a`);
      link.href = url;

      const fileNamePeriodSection = contactResult.statement.period.end ? `_${contactResult.statement.period.end}` : ``;
      link.setAttribute(`download`, `${contactResult.statement.contact.name}${fileNamePeriodSection}_outstanding.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
    catch (e) {
      console.error(e);
      // TODO: how to present error cleanly on portal
    }
    finally {
      setDownloadStatementLoading(false);
      // this may have updated the contact so reload
      refetch();
    }
  }

  async function onDownloadInvoice(line: IStatementLine) {
    if (line.type !== StatementLineType.Invoice) return;

    const invoiceId = line.invoiceId;

    if (!line.hash) {
      throw new Error(`Invoice hash not found`);
    }

    setDownloadingInvoices({
      [invoiceId]: true,
    });

    try {
      const filename = `${line.invoiceNumber}.pdf`;

      const pdfLink = `${env.basePortalApiUrl}invoices/code/${line.hash}/download.pdf`;

      const result = await request({
        method: `GET`,
        responseType: `blob`,
        url: pdfLink,
        params: {
          filename,
          mode: `download`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));

      const link = document.createElement(`a`);
      link.href = url;
      link.target = `_blank`;

      link.setAttribute(`download`, filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (e) {
      const responseObj = await e.response?.data?.text();
      toast.error(`${responseObj ? `${responseObj}` : `Could not download invoice`}`);
    }

    setDownloadingInvoices({
      [invoiceId]: false,
    });
  }

  function onClosePay() {
    setPaymentModalOpen(false);
    setInvoiceIdsToPay([]);
  }

  const successPaymentMessage = checkoutStatus === `complete` ? (
    <Notification
      type={ `successGreen` }
      className={ `mt-4` }
    >
      <div className={ `flex` }>
        <Paragraph
          className={ `text-green-600` }
        >
          { `Payment successful!` }
        </Paragraph>

        { /*  Work around for race condition */ }
        <a
          href={ window.location.href }
          onClick={ () => window.location.reload() }
        >
          <Paragraph
            variant={ `help` }
            className={ `ml-2 underline cursor-pointer text-green-600` }
          >
            { `Reload` }

          </Paragraph>
        </a>
      </div>
    </Notification>
  ) : null;

  if (isLoading) {
    return <LoadingFallback />;
  }

  if (!contactResult.statement.currencySections.length) {
    // No invoices
    return (
      <Card>
        <Paragraph
          variant={ `secondary` }
        >
          { `No outstanding invoices found` }
        </Paragraph>
      </Card>
    );
  }

  return (
    <div>
      { successPaymentMessage }

      <div className={ `space-y-3` }>
        {
          contactResult.statement.currencySections.map(currencySection => {
            return (
              <CurrentStatement
                key={ currencySection.currency }
                currencySection={ currencySection }
                sectionCount={ contactResult.statement.currencySections.length }
                statementPeriod={ contactResult.statement.period }
                mode={ `portal` }
                onDownload={ downloadStatement }
                downloadText= { `Download Statement` }
                downloadTip={ `Download statement as pdf` }
                loading={ downloadStatementLoading }
                organisationTimezone={ contactResult.statement.organisationTimezone }
                childContacts = { contactResult.childContacts }
                onDownloadInvoice={ onDownloadInvoice }
                downloadingInvoices={ downloadingInvoices }
              >
                <When condition={ shouldShowPayButton }>
                  <div className={ `flex justify-end space-x-2 mt-2` }>
                    <Button
                      onClick={ () => onPay(currencySection, `overdue`) }
                      disabled={ !currencySection.lines.some(l => l.type === StatementLineType.Invoice && l.debtAgeInDays > 0) || paymentModalOpen }
                      color={ `orange` }
                    >
                      { `Pay Overdue` }
                    </Button>

                    <Button
                      onClick={ () => onPay(currencySection, `outstanding`) }
                      disabled={ !currencySection.lines.some(l => l.type === StatementLineType.Invoice) || paymentModalOpen }
                      color={ `green` }
                    >
                      { `Pay Outstanding` }
                    </Button>
                  </div>
                </When>
              </CurrentStatement>
            );
          })
        }
      </div>

      <When condition={ !!contactResult.footer }>
        <Card>
          <Paragraph
            variant={ `secondary` }
            className={ `whitespace-pre-wrap` }
          >
            { contactResult.footer }
          </Paragraph>
        </Card>
      </When>
      <When condition={ paymentModalOpen }>
        { () => <StripeCheckout
          mode={ `statement` }
          code={ contactResult.hash }
          connectedAccountId={ contactResult.stripeConnectAccount }
          onClose={ onClosePay }
          invoiceIds={ invoiceIdsToPay }
        /> }
      </When>
    </div>
  );
}
